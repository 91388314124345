import { createAction, props } from '@ngrx/store';

import { Warning } from '../../models';

export const loadRequest = createAction(
  '[Properties Warnings] Load Request',
  props<{ property_id?: number[]; date_from?: string }>(),
);

export const loadSuccess = createAction(
  '[Properties Warnings] Load Success',
  props<{ items: Warning[] }>(),
);

export const loadFailure = createAction(
  '[Properties Warnings] Load Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Properties Warnings] Delete Request',
  props<{
    itemId: number;
    onSuccess?: () => void;
  }>(),
);

export const deleteSuccess = createAction(
  '[Properties Warnings] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Properties Warnings] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Properties Warnings] Reset State');
